<template>
  <div class="blog-detail">
    <AppBreadcrumbs
      v-if="$route.path !== '/'"
      class="container"
    />

    <template v-if="strapiData">
      <BlogHeader
        class="blog-detail__header container"
        :strapi-data="strapiData"
      />

      <StrapiSlices
        :slices="strapiData.Slices"
      />

      <BlogAuthor
        class="blog-detail__author container"
        :author="strapiData?.Author"
      />

      <BlogFooter
        class="blog-detail__footer container"
        :strapi-data="strapiData"
      />

      <!--  -->
      <!--  -->
    </template>

    <DevOnly v-else-if="strapiError">
      <pre class="container">strapiError: {{ strapiError }}</pre>
    </DevOnly>
  </div>
</template>

<script setup lang="ts">
import blogDetailQuery from '~layers/app/graphql/blogDetail.gql'

const meta = inject('meta', ref())
const { strapiData, strapiError, getStrapiData } = useStrapiCms(meta?.value?.strapi_id, undefined, blogDetailQuery, 'blogDetail')
const { t } = useI18n({ useScope: 'global' })

await getStrapiData()

const pageTitle = computed(
  () => strapiData.value?.PageTitle || '',
)

const metaTitle = computed(
  () => strapiData.value?.MetaTitle || pageTitle.value,
)

const metaDescription = computed(
  () => strapiData.value?.MetaDescription || pageTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs({
  label: metaTitle.value,
  parents: meta.value.parents,
})

const { pageView } = useDataLayer()
pageView('blog-detail', metaTitle.value)

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: strapiData.value?.MetaData?.MetaRobots?.replace('_', ', ') },
  ],
})

const route = useRoute()
const { canonical } = useSeo()
canonical(computed(() => route.path))
</script>

<style src="~layers/app/pages/~blog-detail.css" />
