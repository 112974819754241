<template>
  <footer class="blog-detail__footer">
    <AppLink
      v-if="parent?.url"
      :to="`/${parent.url}/`"
      class="blog-detail__button button"
    >
      <IconArrow class="icon icon--arrow-left" />
      <span>Terug naar {{ strapiData?.ParentPage?.PageTitle }}</span>
    </AppLink>
  </footer>
</template>

<script setup lang="ts">
defineProps<{
  strapiData: any
}>()

const { breadcrumbs } = useBreadcrumbs()

const parent = computed(() => {
  return breadcrumbs.value?.parents?.toReversed()?.[0]
})
</script>

<style src="~layers/app/components/Blog/Footer.css" />
